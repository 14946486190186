import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/purchasing',
        redirect: '/purchasing/cart'
    },
    {
        path: '/purchasing/cart',
        name: 'Cart',
        meta: {
            requiresAuth: true,
        },
        component: () => import('@/views/purchasing/CartPage.vue')
    },
    {
        path: '/purchasing/shippingMethod',
        name: 'ShippingMethod',
        meta: {
            requiresAuth: true,
        },
        component: () => import('@/views/purchasing/ShippingMethodPage.vue'),
    },
    {
        path: '/purchasing/shippingAddress',
        name: 'ShippingAddress',
        meta: {
            requiresAuth: true,
        },
        component: () => import('@/views/purchasing/ShippingAddressPage.vue')
    },
    {
        path: '/purchasing/paymentMethods',
        name: 'PaymentMethods',
        meta: {
            requiresAuth: true,
        },
        component: () => import('@/views/purchasing/PaymentMethodPage.vue')
    },
    {
        path: '/purchasing/orderSummary',
        name: 'OrderSummary',
        meta: {
            requiresAuth: true,
        },
        component: () => import('@/views/purchasing/OrderSummaryPage.vue')
    },
    {
        path: '/purchasing/transactionResponse/:orderId/:transactionStatus/:transactionMessage?',
        name: 'TransactionResponse',
        meta: {
            requiresAuth: true,
        },
        props: true,
        component: () => import('@/views/purchasing/TransactionResponsePage.vue')
    },
]

export default routes