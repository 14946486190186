import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/admin',
        redirect: '/admin/dashboard'
    },
    {
        path: '/admin',
        name: 'AdminHomePage',
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
        },
        component: () => import('@/views/admin/AdminHomePage.vue'),
        children: [
            {
                path: 'dashboard',
                name: 'AdminDashboardPage',
                component: () => import('@/views/admin/AdminDashboardPage.vue'),
            },
            {
                path: 'productos',
                name: 'AdminProductsPage',
                component: () => import('@/views/admin/AdminProductsPage.vue'),
            },
            {
                path: 'productos/crear-nuevo',
                name: 'AdminProductsCreatePage',
                component: () => import('@/views/admin/AdminProductsCreatePage.vue'),
            },
            {
                path: 'productos/importar',
                name: 'AdminProductsImportPage',
                component: () => import('@/views/admin/AdminProductsImportPage.vue'),
            },
        ],
    },
]

export default routes