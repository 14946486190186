import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router'
import { useAuth } from '@/services/useAuth';
import { usePurchasingProcessStore } from "@/stores/usePurchasingProcessStore";
import categoriesRoutes from "./categoriesRoutes"
import purchasingRoutes from "./purchasingRoutes"
import authRoutes from "./authRoutes"
import adminRoutes from "./adminRoutes"
import { useAuthStore } from '@/stores/useAuthStore';

const routes: Array<RouteRecordRaw> = [
    // {
    //   path: '/',
    //   name: 'Landing',
    //   component: () => import('../views/Landing.vue')
    // },
    {
        path: '/',
        redirect: '/categorias'
    },
    {
        path: '/home',
        redirect: '/categorias'
    },
    {
        path: '/home',
        name: 'Home',
        meta: {
            requiresAuth: true,
        },
        component: () => import('@/views/HomePage.vue')
    },
    ...categoriesRoutes,
    ...authRoutes,
    ...purchasingRoutes,
    ...adminRoutes,
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/error/NotFoundPage.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    const { isUserLogin } = useAuth()

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        const isLogged = await isUserLogin()
        if (isLogged) {
            if (to.matched.some((record) => record.meta.requiresAdmin)) {
                const { user } = useAuthStore()
                if ( user?.roles.includes('admin') ) {
                    next()
                } else {
                    next({
                        path: "/home",
                    });
                }
            } else {
                next()
            }
        } else {
            next({
                name: "Login",
                query: {
                    to: to.path
                }
            });
        }
    } else {
        next(); // make sure to always call next()!
    }
})

router.afterEach(async (to) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if ( to.name ) {
            const { navigation, setCurrentStepId } = usePurchasingProcessStore()
            if ( navigation.includes(to.name.toString()) ) {
                setCurrentStepId(to.name.toString())
            } else {
                setCurrentStepId(navigation[0]) // Cart
            }
        }
    }
})

export default router

