import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/auth/LoginPage.vue')
    },
    {
        path: '/registro',
        name: 'Register',
        component: () => import('@/views/auth/RegisterPage.vue')
    },
]

export default routes