import axios from 'axios'
import { Preferences} from "@capacitor/preferences"
import { useAuthStore } from "@/stores/useAuthStore";
import { useCartStore } from '@/stores/useCartStore';
import utils from '@/utils';
// import { HTTPResponse } from "@/types";

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_AUTHENTICATION,
})


export function useAuth() {
    const { setCart } = useCartStore()
    const authStore = useAuthStore()
    
    apiClient.interceptors.request.use((config: any) => {
        config.headers.token = authStore.token;
        config.params = { ...config.params }
        return config;
    });


    const loginWithPhone = async (data: any) => {
        return apiClient.post('/login/phone', data)
    }

    const registerWithPhone = (data: any) => {
        return apiClient.post('/register', data)
    }

    const getRefreshToken = async () => {
        return apiClient.get('/refresh_token')
    }
    
    const getUser = async () => {
        return apiClient.get('/me')
    }
    
    const isUserLogin = async (): Promise<boolean> => {
        if (!authStore.token) {
            const tokenStorage: any = await Preferences.get({ key: 'token' })
            const refreshTokenStorage: any = await Preferences.get({ key: 'refresh_token' })
            
            if (tokenStorage.value) {
                if (utils.tokenAlive(tokenStorage.value)) {
                    // Token is alive in localStorage
                    // Set Token in Store
                    authStore.setToken(tokenStorage.value)
                    if (refreshTokenStorage.value && refreshTokenStorage.value !== "undefined") {
                        authStore.setRefreshToken(refreshTokenStorage.value)
                    }
      
                    if (!authStore.user) {
                        await authStore.setUser()
                        await setCart()
                    }
      
                    return true
                } else {
                    // if (refreshTokenStorage.value) {
                    //     try {
                    //         const response: HTTPResponse = await getRefreshToken({
                    //             token: tokenStorage.value,
                    //             refresh_token: refreshTokenStorage.value,
                    //         })
      
                    //         if (response.status === 200) {
                    //             const tokeResponse = response.data.token
                    //             const refreshTokeResponse = response.data.refresh_token
                    //             // Save data in storage
                    //             Preferences.set({ key: 'token', value: tokeResponse })
                    //             Preferences.set({ key: 'reresh_token', value: refreshTokeResponse })
      
                    //             setToken(tokeResponse)
                    //             setRefreshToken(refreshTokeResponse)
      
                    //             await setUser()
      
                    //             return true
                    //         } else {
                    //             logout()
                    //             return false
                    //         }
                    //     } catch (error: any) {
                    //         logout()
                    //         return false
                    //     }
                    // } 
                    // else {
                        // Refresh token is dead
                        authStore.logout()
                        return false
                    // }
                }
            } else {
                return false
            }
        } else {
            if (utils.tokenAlive(authStore.token)) {
                // Token is alive in store
                return true
            } else {
                try {
                    // const response = await getRefreshToken({ token, refresh_token })
      
                    // if (response.status === 200) {
                    //     const tokeResponse = response.data.token
                    //     const refreshTokeResponse = response.data.refresh_token
                    //     // Save data in storage
                    //     Preferences.set({ key: 'token', value: tokeResponse })
                    //     Preferences.set({ key: 'reresh_token', value: refreshTokeResponse })
      
                    //     setToken(tokeResponse)
                    //     setRefreshToken(refreshTokeResponse)
      
                    //     await setUser()
      
                    //     return true
                    // } 
                    // else {
                        authStore.logout()
                      return false
                    // }
                } catch (error: any) {
                    // Loguot
                    authStore.logout()
                    return false;
                }
            }
        }
    }

    return {
        registerWithPhone,
        loginWithPhone,
        getRefreshToken,
        getUser,
        isUserLogin
    }
}