import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/categorias',
        name: 'Categories',
        meta: {
            requiresAuth: true,
        },
        component: () => import('@/views/categories/CategoriesPage.vue'),
    },
    {
        path: '/categorias/:categoryId/:categorySlug?',
        name: 'CategoryDetail',
        meta: {
            requiresAuth: true,
        },
        component: () => import('@/views/categories/CategoryDetailPage.vue')
    },
]

export default routes