import jwt_decode from "jwt-decode"
import currency from 'currency.js'
import { toastController  } from '@ionic/vue';
import { ToastOptions } from "@/types";

export const formatPrice = (price: number) => {
    return currency(price, { precision: 0, separator: '.'}).format()
}

export const tokenAlive = (token: any): boolean => {
    const t:any = jwt_decode(token)
    const copy = new Date();
    copy.setTime(t.exp * 1000)

    if(Date.now() > t.exp * 1000) return false;
    return true;
}

export const validateEmail = (email: string) => {
	return email.match(
		/^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
	);
}

export const responseFormatter = (response: any) => {
    return {
        statusCode: response.status,
        message: response.data.results.message,
    }
}

export const presentToast = async ({ position, message, duration, buttons }: ToastOptions ) => {
    const toast = await toastController.create({
        message: message || '',
        duration: duration || 2000,
        position: position || 'bottom',
        cssClass: 'custom-toast',
        buttons,
    })

    await toast.present()
}

export default {
    presentToast,
    formatPrice,
    tokenAlive,
    validateEmail,
    responseFormatter
}