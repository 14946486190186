import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Toast from "vue-toastification";
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import "vue-toastification/dist/index.css";

/* Theme variables */
import './theme/variables.css';
import './assets/styles/main.scss'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// Icons
import { faCircleNotch, faArrowLeft, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'

/* add icons to the library */
library.add(faCircleNotch, faArrowLeft, faCheck, faXmark, faTrashCan)

const pinia = createPinia()
const app = createApp(App)
app.use(pinia)
app.use(IonicVue)
app.use(router)
app.use(Toast, {});

app.component('FontAwesomeIcon', FontAwesomeIcon)

router.isReady().then(() => {
  app.mount('#app');
});