import { ref } from "vue";
import { defineStore } from "pinia";

export const usePurchasingProcessStore = defineStore('purchasingProcessStore', () => {
    const navigation = ref(['Cart', 'ShippingMethod', 'ShippingAddress', 'PaymentMethods', 'OrderSummary'])
    const currentStepId = ref('Cart')

    const steps: any = ref({
        'Cart': {
            name: 'Cart',
            url: '/purchasing/cart',
            title: 'Carrito',
        },
        'ShippingMethod': {
            name: 'ShippingMethod',
            url: '/purchasing/shippingMethod',
            title: '¿Como quieres recibir tu pedido?',
        },
        'ShippingAddress': {
            name: 'ShippingAddress',
            url: '/purchasing/shippingAddress',
            title: 'Datos de envío',
        },
        'PaymentMethods': {
            name: 'PaymentMethods',
            url: '/purchasing/paymentMethods',
            title: '¿Como quieres pagar?',
        },
        'OrderSummary': {
            name: 'OrderSummary',
            url: '/purchasing/orderSummary',
            title: 'Resumen de la orden',
        },
        // 'FinalizingOrder': {
        //     name: 'FinalizingOrder',
        //     url: '/purchasing/finalizingOrder',
        //     title: 'Finalizar pedido',
        // },
    })

    const setCurrentStepId = (stepId: string) => {
        currentStepId.value = stepId
    }

    const getPreviousStep = (): string | boolean => {
        const currentStepIndex = navigation.value.indexOf(currentStepId.value)
        const previousStepIndex = currentStepIndex - 1

        if ( previousStepIndex >= 0 ) {
            return navigation.value[previousStepIndex]
        } else {
            return false
        }
    }

    const getNextStep = (): string | boolean => {
        const currentStepIndex = navigation.value.indexOf(currentStepId.value)
        const nextStepIndex = currentStepIndex + 1

        if ( nextStepIndex <= navigation.value.length ) {
            return navigation.value[nextStepIndex]
        } else {
            return false
        }
    }

    return {
        navigation,
        steps,
        currentStepId,
        setCurrentStepId,
        getPreviousStep,
        getNextStep
    }
})