import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { Cart, CartItem, PaymentMethod, ShippingAddressWithFullCityAndState, ShippingMethod } from "@/types";
import { Preferences } from "@capacitor/preferences"
const setCartInit = () => ({
    total: 0,
    subtotal: 0,
    quantity: 0,
    discount: 0,
    products: [],
    paymentMethod: undefined,
    shippingMethod: undefined,
    shippingAddress: undefined,
    shippingAdditionalCost: 0
})

const cart = ref<Cart>(setCartInit())
export const useCartStore = defineStore('cartStore', () => {

    const totalCart = computed(() => cart.value.total)

    const setCart = async () => {
        const localCart: any = await Preferences.get({key: 'cart'})
        if ( localCart.value ) {
            cart.value = JSON.parse(localCart.value)
        } 
    }

    const findProduct = (productsCart: CartItem[], id: number): CartItem | undefined =>  {
        const productFinded = productsCart.find((product: CartItem) => product.id === id)
        return productFinded
    }   
    
    const calculateTotal = () => {
        // debugger
        // Initialize variables for subtotal and total
        let subtotal = 0; // Variable to store the subtotal
        let total = 0; // Variable to store the total
    
        // Iterate through each product in the cart
        cart.value.products.forEach(product => {    
            // Calculate the product subtotal by multiplying the price by the quantity
            subtotal += product.price * product.quantity;
    
            // Subtract the product discount from the subtotal to get the product total
            total = subtotal - (product.discount * product.quantity);
        });
    
        // Update the subtotal in the cart.value object
        cart.value.subtotal = subtotal;
    
        // Subtract the cart discount and add shippingAdditionalCost from the total to get the final total
        cart.value.total = total - cart.value.discount + cart.value.shippingAdditionalCost;
    }
    
    const addProduct = ({ product, quantity }: any): boolean => {
        try {
            const { id, name, image, price, original_price, stock, discount = 0 } = product

            if ( quantity === 0 ) {
                throw Error('Cantidad debe ser mayor a cero.')
            }
            
            const isProductAdded: CartItem | undefined = findProduct(cart.value.products, id)
            
            if ( isProductAdded ) {
                isProductAdded.quantity += quantity
            } else {
                cart.value.products.push({ id, name, image, quantity, price, original_price, stock, discount })
            }

            cart.value.quantity += quantity
            cart.value.subtotal += (original_price > price ? original_price : price) * quantity
            cart.value.total = cart.value.subtotal

            calculateTotal()
            saveCartLocally()
            return true

        } catch ( error: any ) {
            console.log(error)
            throw error
        }
    }

    const deleteProduct = (id: number): boolean => {
        const product: CartItem | undefined = findProduct(cart.value.products, id)
        if ( !product ) return false

        const index = cart.value.products.indexOf(product)
        const removedProduct = cart.value.products.splice(index, 1)

        if ( !removedProduct ) return false

        cart.value.quantity -= removedProduct[0].quantity

        calculateTotal()
        saveCartLocally()
        return true
    }

    const decreaseQuantity = (id: any): boolean => {
        const product: CartItem | undefined = findProduct(cart.value.products, id)
        if ( !product ) return false

        if ( product.quantity <= 1  ) {
            alert('No puedes pedir menos de 1 unidad')
            return false
        }

        product.quantity -= 1
        cart.value.quantity -= 1 

        calculateTotal()
        saveCartLocally()
        return true
    }

    const increaseQuantity = (id: any): boolean => {
        const product: CartItem | undefined = findProduct(cart.value.products, id)

        if ( !product ) return false

        if ( product.quantity >= product.stock  ) {
            alert(`Solo hay ${product.stock} productos disponibles.`)
            return false
        }

        product.quantity += 1
        cart.value.quantity += 1 

        calculateTotal()
        saveCartLocally()
        return true
    }

    const saveCartLocally = async () => {
        await Preferences.set({ key: 'cart', value: JSON.stringify(cart.value) })
    }
    
    const deleteCartLocally = async () => {
        await Preferences.remove({ key: 'cart' })
    }

    const saveShippingMethod = (shippingMethod: ShippingMethod, additionalCost: number) => {
        cart.value.shippingMethod = shippingMethod
        cart.value.shippingAdditionalCost = additionalCost
        calculateTotal()
        saveCartLocally()
    }

    const saveShippingAddress = (shippingAddress: ShippingAddressWithFullCityAndState | undefined) => {
        cart.value.shippingAddress = shippingAddress
        saveCartLocally()
    }

    const savePaymentMethod = (paymentMethod: PaymentMethod) => {
        cart.value.paymentMethod = paymentMethod
        saveCartLocally()
    }

    const clearCart = async () => {
        cart.value = setCartInit()
        await deleteCartLocally()
        await saveCartLocally()
    }

    return {
        cart,
        totalCart,
        setCart,
        addProduct,
        decreaseQuantity,
        increaseQuantity,
        deleteProduct,
        saveShippingMethod,
        saveShippingAddress,
        savePaymentMethod,
        clearCart
    }
})