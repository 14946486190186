import { ref } from "vue";
import { defineStore } from "pinia";
import { Preferences } from "@capacitor/preferences"
import { useAuth } from "@/services/useAuth";
import { UserWithShippingAddress } from "@/types";

export const useAuthStore = defineStore('auth', () => {
    const user = ref<UserWithShippingAddress | undefined>(undefined)
    const isLogged = ref(false)
    const token = ref('')
    const refresh_token = ref('')

    const setToken = async (newToken: string) => {
        token.value = newToken
        isLogged.value = true
        Preferences.set({ key: 'token', value: newToken })
        await setUser()
    }
    
    const setRefreshToken = (newRefreshToken: string) => {
        refresh_token.value = newRefreshToken
        Preferences.set({ key: 'refresh_token', value: newRefreshToken })
    }

    // const getRefreshToken = (payload: { token: string, refresh_token: string }) => {
    //     // if ( process.env.NODE_ENV === 'development' ) {
    //     //     return new Promise((resolve) => {
    //     //         const response: HTTPResponse = {
    //     //             status: 200,
    //     //             data: {
    //     //                 token: '123456',
    //     //                 refresh_token: '123456'
    //     //             }
    //     //         }

    //     //         resolve(response)
    //     //     })
    //     // } else {
    //     //     apiClient.interceptors.request.use((config: any) => {
    //     //         config.headers.common["token"] = payload.token;
    //     //         config.headers.common["refresh_token"] = payload.refresh_token;
    //     //         return config;
    //     //     })
    
    //     //     return apiClient.post('/auth/refresh-token')
    //     // }
    //     return false
    // }

    const setUser = async () => {
        const { getUser } = useAuth()
        try {
            const response = await getUser()
            user.value =  response.data.results.user

        } catch ( error ) {
            console.log(error)
            user.value = undefined
        }
    }

    const logout = async () => {
        token.value = ''
        refresh_token.value = ''
        user.value = undefined
        isLogged.value = false

        Preferences.remove({ key: 'token' })
        Preferences.remove({ key: 'refresh_token' })
        Preferences.remove({ key: 'user' })
        Preferences.remove({ key: 'cart' })
    }

    return {
        user,
        token,
        refresh_token,
        isLogged,
        setToken,
        setRefreshToken,
        // getRefreshToken,
        setUser,
        logout
    }
})